/* 导航栏 */
.navbar {
  background: #003285;
  color: white;
  font-size: 1.2rem; /* 字体大小 */
  font-weight: bold; /* 字体加粗 */
  margin-bottom: 10px; /* 导航栏与下方内容的间距 */
}

.navbar-nav .nav-link {
  color: white;
  font-size: 1.2rem; /* 调整字体大小 */
  font-weight: bold; /* 设置字体加粗 */
}

.logo {
  height: 50px; /* Logo 高度 */
}

/* 轮播图 */
.carousel-container {
  width: 100%;
  height: 25vw;
  margin-bottom: 10px; /* 增加轮播图与下方内容的间距 */
}

.carousel-item {
  height: 25vw; /* 轮播图高度 */
}

/* 产品介绍卡片 */
.card {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  background-color: #003285; /* 卡片背景色 */
  color: white; /* 卡片文字颜色 */
}

.card:hover {
  transform: scale(1.05); /* 悬停时放大效果 */
}

.card-img-top {
  height: 200px;
  object-fit: cover; /* 图片裁剪 */
}

.card-body {
  padding: 15px;
  text-align: center; /* 文字居中 */
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: white; /* 标题颜色 */
  margin-bottom: 10px;
}

.card-text {
  font-size: 1rem;
  color: #ccc; /* 描述文本颜色 */
}

/* 客户评价卡片 */
.review-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  background-color: #fff;
  color: #333;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rating {
  display: flex;
  justify-content: center;
  align-items: center;
}

.star {
  color: #ffbf00; /* 星星颜色 */
  font-size: 1.2rem;
  margin-right: 5px;
}

/* 联系方式 */
.contact-info {
  background-color: #003285;
  color: white;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
}

/* 信息部分样式 */
.info-section {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 20px;
}

/* 信息部分标题样式 */
.info-section h5 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: left;
}

/* 信息部分文本样式 */
.info-section p {
  font-size: 0.8rem;
  line-height: 1.5;
  text-align: left;
}

/* 社交媒体列表 */
.social-media-list {
  list-style-type: none; /* 去掉列表符号 */
  padding: 0;
  margin: 0;
}

.social-media-list li {
  display: inline-block;
  margin: 10px;
}

.social-media-list a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  transition: color 0.3s ease, transform 0.3s ease; /* 过渡效果 */
  display: flex;
  align-items: center; /* 图标与文字对齐 */
}

.social-media-list a:hover {
  transform: scale(1.05); /* 悬停时放大效果 */
}

.social-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  transition: transform 0.3s ease; /* 旋转效果 */
}

.social-icon:hover {
  transform: rotate(360deg); /* 悬停时旋转效果 */
}

/* 地图容器样式 */
.map-container {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
}

/* 整体页面背景颜色 */
body {
  background-color: #f4f4f4;
}

/* 标题颜色 */
h2, h5 {
  color: #003285;
}
